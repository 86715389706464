import { useEffect, useMemo, useState } from "react";
import { Modal, Button, DatePicker, Input } from "antd";
import { EventValue, RangeValue } from "rc-picker/lib/interface";
import { Dayjs } from "dayjs";
import { ICheckinParams } from "../../../services/hcnApi";

const { RangePicker } = DatePicker;
const DEFAULT_SRP = "HCNHCN";
interface CheckinModalProps {
  onSubmit: (params: ICheckinParams) => any | undefined;
  onCancel: () => void;
  open: boolean;
  hotelData: { id: string; name: string };
  room: string;
  macAddress: string;
}
const CheckinModal = ({
  onSubmit,
  onCancel,
  open,
  hotelData,
  macAddress,
  room,
}: CheckinModalProps) => {
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [checkIn, setCheckIn] = useState<EventValue<Dayjs> | null>(null);
  const [checkOut, setCheckOut] = useState<EventValue<Dayjs> | null>(null);
  const [guestGroupCode, setGuestGroupCode] = useState<string | undefined>(
    DEFAULT_SRP
  );

  const [loading, setLoading] = useState(false);

  const isDisabled = useMemo(() => {
    return !room || !userId || !checkIn || !checkOut || loading;
  }, [room, userId, checkIn, checkOut, loading]);

  const clearState = () => {
    setUserId(undefined);
    setCheckIn(null);
    setCheckOut(null);
    setLoading(false);
    setGuestGroupCode(undefined);
  };

  const checkinHandler = async () => {
    if (!room || !userId || !checkIn || !checkOut || !guestGroupCode) return;
    setLoading(true);

    await onSubmit({
      room,
      checkIn: checkIn.valueOf(),
      checkOut: checkOut.valueOf(),
      userId,
      propertyId: hotelData.id,
      guestGroupCode,
    });
    setLoading(false);
  };

  const handleDateChange = (
    dates: RangeValue<Dayjs>,
    dateStrings: [string, string]
  ) => {
    if (!dates) {
      setCheckIn(null);
      setCheckOut(null);
      return;
    }
    setCheckIn(dates[0]);
    setCheckOut(dates[1]);
  };

  useEffect(() => {
    if (!open) {
      clearState();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      closable={false}
      footer={[
        <Button
          key="confirm"
          disabled={isDisabled}
          onClick={() => checkinHandler()}
        >
          Confirm
        </Button>,
        <Button
          key="cancel"
          onClick={() => {
            clearState();
            onCancel();
          }}
        >
          Cancel
        </Button>,
      ]}
    >
      <p>
        Name: {hotelData.name}, ID: {hotelData.id}
      </p>
      <p>MacAddress: {macAddress}</p>
      <label>
        <p style={{ marginBottom: 2 }}>User</p>
        <Input
          maxLength={40}
          style={{ width: "100%" }}
          placeholder="Enter username"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
        />
      </label>

      <label htmlFor="check-dates">
        <div style={{ display: "flex", width: "100%", marginBottom: -10 }}>
          <p style={{ flexGrow: 1 }}>Check-in</p>
          <p style={{ flexGrow: 1 }}>Check-out</p>
        </div>
        <RangePicker
          id="check-dates"
          style={{ width: "100%" }}
          value={[checkIn, checkOut]}
          onChange={handleDateChange}
          allowClear
          placeholder={["Check-in Date", "Check-out Date"]}
        />
      </label>

      <label htmlFor="srp-code">
        <p style={{ marginBottom: 2 }}>SRP Code</p>
        <Input
          id="srp-code"
          type="text"
          defaultValue={DEFAULT_SRP}
          value={guestGroupCode}
          onChange={(e) => setGuestGroupCode(e.target.value)}
          placeholder="Enter SRP Code"
          style={{ width: "100%" }}
        />
      </label>
    </Modal>
  );
};

export default CheckinModal;
